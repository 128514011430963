import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';

import { useAppContext } from '@/context/appContext';
import logoIcon from '@/style/images/logo-icon.png';
import logoText from '@/style/images/logo-text.png';

import {
  DesktopOutlined,
  SettingOutlined,
  CustomerServiceOutlined,
  FileTextOutlined,
  FileSyncOutlined,
  DashboardOutlined,
  TeamOutlined,
  UserOutlined,
  CreditCardOutlined,
  BankOutlined,
  CodeSandboxOutlined,
} from '@ant-design/icons';

const { Sider } = Layout;
const { SubMenu } = Menu;

export default function Navigation() {
  const { state: stateApp, appContextAction } = useAppContext();
  const { isNavMenuClose } = stateApp;
  const { navMenu } = appContextAction;
  const [showLogoApp, setLogoApp] = useState(isNavMenuClose);

  useEffect(() => {
    if (isNavMenuClose) {
      setLogoApp(isNavMenuClose);
    }
    const timer = setTimeout(() => {
      if (!isNavMenuClose) {
        setLogoApp(isNavMenuClose);
      }
    }, 200);
    return () => clearTimeout(timer);
  }, [isNavMenuClose]);
  const onCollapse = () => {
    navMenu.collapse();
  };

  return (
    <>
      <Sider collapsible collapsed={isNavMenuClose} onCollapse={onCollapse} className="navigation">
        <div
          className="logo"
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <img
            src={logoIcon}
            alt="Logo"
            style={{ height: '35px', width: '32px', display: 'block' }}
          />
          <br />

          {!showLogoApp && (
            // <img src={logoText} alt="Logo" style={{ marginTop: '3px', marginLeft: '10px' }} />
            <p alt="Logo" style={{ marginTop: '3px', marginLeft: '10px' }}>
              Consulate General of Bangladesh
            </p>
          )}
        </div>
        <br /> <br />
        <Menu mode="inline">
          <Menu.Item key={'Dashboard'} icon={<DashboardOutlined />}>
            <Link to={'/'} />
            Dashboard
          </Menu.Item>

          {/* Hided customers now, 
          <Menu.Item key={'Customer'} icon={<CustomerServiceOutlined />}>
            <Link to={'/customer'} />
            Customer
          </Menu.Item> */}

          <SubMenu key={'Vouchers'} icon={<FileTextOutlined />} title={'Vouchers'}>
            <Menu.Item key={'Payment'}>
              <Link to={'/payment-voucher'} />
              Payment
            </Menu.Item>
            <Menu.Item key={'Reciept'}>
              <Link to={'/receipt-voucher'} />
              Receipt
            </Menu.Item>
          </SubMenu>

          {/* Quote hided
          <Menu.Item key={'Quote'} icon={<FileSyncOutlined />}>
            <Link to={'/quote'} />
            Quote
          </Menu.Item> */}
          {/* Renamed Payment invoice to payment scheduler, Progressive Report and reciept schedule */}
          <Menu.Item key={'Payments Schedule'} icon={<CreditCardOutlined />}>
            <Link to={'/payments-schedule'} />
            Payments Schedule
          </Menu.Item>
          <Menu.Item key={'Receipts Schedule'} icon={<CreditCardOutlined />}>
            <Link to={'/receipts-schedule'} />
            Receipts Schedule
          </Menu.Item>
          <Menu.Item key={'Progressive Report'} icon={<CreditCardOutlined />}>
            <Link to={'progressive-report'} />
            Progressive Report
          </Menu.Item>
          {/* Edited Employee section and added salary
          <Menu.Item key={'Employee'} icon={<UserOutlined />}>
            <Link to={'/employee'} />
            Employee
          </Menu.Item> */}
          <SubMenu key={'Employee'} icon={<UserOutlined />} title={'Employee'}>
            <Menu.Item key={'Employee List'}>
              <Link to={'/employee'} />
              Employee List
            </Menu.Item>
            <Menu.Item key={'Salary'}>
              <Link to={'/employee'} />
              Salary
            </Menu.Item>
          </SubMenu>

          <SubMenu key={'Wings'} icon={<TeamOutlined />} title={'Wings'}>
            <Menu.Item key={'Wings List'}>
              <Link to={'/wings/wing-list'} />
              Wings List
            </Menu.Item>
            <Menu.Item key={'Wings Assignment'}>
              <Link to={'/wings/wings-assignment'} />
              Wings Assignment
            </Menu.Item>
          </SubMenu>

          <Menu.Item key={'Admin'} icon={<TeamOutlined />}>
            <Link to={'/admin'} />
            Admin
          </Menu.Item>

          <SubMenu key={'Settings'} icon={<SettingOutlined />} title={'Settings'}>
            <Menu.Item key={'SettingsPage'}>
              <Link to={'/settings'} />
              General Settings
            </Menu.Item>
            <Menu.Item key={'PaymentMode'}>
              <Link to={'/payment/mode'} />
              Payment Mode
            </Menu.Item>
            <Menu.Item key={'Role'}>
              <Link to={'/role'} />
              Role
            </Menu.Item>
            <Menu.Item key={'Mission Code'}>
              <Link to={'/role'} />
              Mission Code
            </Menu.Item>
            <Menu.Item key={'Budget Code'}>
              <Link to={'/role'} />
              Budget Code
            </Menu.Item>
            <SubMenu key={'Manage Cash'} icon={<CodeSandboxOutlined />} title={'Manage Cash'}>
              <Menu.Item key={'Bank'}>
                <Link to={'/currency'} />
                Bank
              </Menu.Item>
              <Menu.Item key={'Cash in hand'}>
                <Link to={'/currency'} />
                Cash in hand
              </Menu.Item>
            </SubMenu>
            <SubMenu key={'Currency'} icon={<CodeSandboxOutlined />} title={'Currency'}>
              <Menu.Item key={'Base Currency'}>
                <Link to={'/currency'} />
                Base Currency
              </Menu.Item>
              <Menu.Item key={'Exchange Rates'}>
                <Link to={'/currency'} />
                Exchange Rates
              </Menu.Item>
              <Menu.Item key={'Foreign Currency'}>
                <Link to={'/currency'} />
                Foreign Currency
              </Menu.Item>
            </SubMenu>
          </SubMenu>
        </Menu>
      </Sider>
    </>
  );
}
