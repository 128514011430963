import React from 'react';
// import {
//   DesktopOutlined,
//   SettingOutlined,
//   CustomerServiceOutlined,
//   FileTextOutlined,
//   FileSyncOutlined,
//   DashboardOutlined,
//   TeamOutlined,
//   UserOutlined,
//   CreditCardOutlined,
//   BankOutlined,
// } from "@ant-design/icons";

// export const IconMenu = ({ name }) => {
//   const components = {
//     DesktopOutlined: DesktopOutlined,
//     SettingOutlined: SettingOutlined,
//     CustomerServiceOutlined: CustomerServiceOutlined,
//     FileTextOutlined: FileTextOutlined,
//     FileSyncOutlined: FileSyncOutlined,
//     DashboardOutlined: DashboardOutlined,
//     TeamOutlined: TeamOutlined,
//     UserOutlined: UserOutlined,
//     CreditCardOutlined: CreditCardOutlined,
//     BankOutlined: BankOutlined,
//     Default: DesktopOutlined,
//   };

//   const IconTag = components[name || "Default"] || SettingOutlined;
//   return <IconTag />;
// };

export const routesConfig = [
  {
    path: '/',
    component: 'Dashboard',
  },
  {
    path: '/customer',
    component: 'Customer',
  },
  {
    path: '/invoice',
    component: 'Invoice',
  },

  // {
  //   path: '/quote',
  //   component: 'Quote',
  // },
  // {
  //   path: '/payment/invoice',
  //   component: 'PaymentInvoice',
  // },
  {
    path: '/employee',
    component: 'Employee',
  },

  {
    path: '/admin',
    component: 'Admin',
  },
  {
    path: '/settings',
    component: 'Settings/Settings',
  },
  {
    path: '/payment/mode',
    component: 'PaymentMode',
  },
  {
    path: '/role',
    component: 'Role',
  },
  {
    path: '/profile',
    component: 'Profile',
  },
  {
    path: '/payment-voucher',
    component: 'Payment-Voucher',
  },
  {
    path: '/receipt-voucher',
    component: 'Receipt-Voucher',
  },
  {
    path: '/payments-schedule',
    component: 'Payments-Schedule',
  },
  {
    path: '/receipts-schedule',
    component: 'Receipts-Schedule',
  },
  {
    path: '/progressive-report',
    component: 'Progressive-Report',
  },
  {
    path: '/currency',
    component: 'Currency',
  },

  {
    path: '/wings/wing-list',
    component: 'Wing-List',
  },
  {
    path: '/wings/wings-assignment',
    component: 'Wings-Assignment',
  },
];
